export class CookieManager {
    static getCookie(name: string): string | undefined {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()?.split(';').shift();
    }

    static setCookie(name: string, value: string, days: number = 7): void {
        const expires = new Date(Date.now() + days * 864e5).toUTCString();
        document.cookie = `${name}=${value}; expires=${expires}; path=/`;
    }

    static deleteCookie(name: string): void {
        CookieManager.setCookie(name, '', -1);
    }
}
