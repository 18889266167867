import {BaseApi} from "@/api/base/BaseApi";
import {components} from "backend-types";

type PopularGenreResponse = components["schemas"]["PopularGenreResponse"]
type GenreResponse = components["schemas"]["GenreResponse"]

const API_PREFIX = '/backend'

export class GenreApi {
    private client: BaseApi;

    constructor() {
        this.client = new BaseApi();
    }


    public async fetchPopularGenres(sortDirection: string, limit: number): Promise<PopularGenreResponse[]> {
        try {
            return this.client.get<PopularGenreResponse[]>(API_PREFIX + '/genre/popular', {
                params: {
                    sortDirection,
                    limit
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`An error occurred while fetching popular genres: ${error}`);
        }
    }

    public async fetchAllSongGenres(): Promise<GenreResponse[]> {
        try {
            return this.client.get<GenreResponse[]>(API_PREFIX + '/genre/all', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        } catch (error) {
            throw new Error(`An error occurred while fetching all song genres: ${error}`);
        }
    }


}

export default new GenreApi();
