import { BaseApi } from "@/api/base/BaseApi";
import { CookieManager } from "@/api/base/CookieManager";
import { components } from "user-types";

type RegistrationRequest = components["schemas"]["UserRegistrationRequest"];
type JwtAuthenticationResponse = components["schemas"]["JwtAuthenticationResponse"];
type UserSignInRequest = components["schemas"]["UserSignInRequest"];

const API_PREFIX = '/user'

type RegistrationRequestFormData = {
    photo: File;
    user: Omit<RegistrationRequest, 'photo'>;
};

export class AuthApi {
    private client: BaseApi;

    constructor() {
        this.client = new BaseApi();
    }

    public async signUpUser(requestBody: RegistrationRequestFormData): Promise<JwtAuthenticationResponse> {
        try {
            const formData = new FormData();
            formData.append('photo', requestBody.photo, 'photo.jpg');
            formData.append('user', new Blob([JSON.stringify(requestBody.user)], { type: 'application/json' }));

            const response = await this.client.post<JwtAuthenticationResponse>(API_PREFIX + '/sign-up', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response && response.token) {
                CookieManager.setCookie('token', response.token);
            }

            return response;
        } catch (error) {
            throw new Error(`Failed to sign up user: ${error}`);
        }
    }

    public async signInUser(requestBody: UserSignInRequest): Promise<JwtAuthenticationResponse> {
        try {
            const response = await this.client.post<JwtAuthenticationResponse>(API_PREFIX + '/sign-in', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response && response.token) {
                CookieManager.setCookie('token', response.token);
            }

            return response;
        } catch (error) {
            throw new Error(`Failed to sign in user: ${error}`);
        }
    }
}

export default new AuthApi();
