import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomePage from '@/pages/HomePage.vue';
import AllGenresPage from '@/pages/AllGenresPage.vue';
import AllMusiciansPage from '@/pages/AllMusiciansPage.vue';
import ChordLibraryPage from '@/pages/ChordLibraryPage.vue';
import TunerPage from '@/pages/TunerPage.vue';
import SongPage from '@/pages/SongPage.vue';
import AddSongPage from '@/pages/AddSongPage.vue';
import SongsLibrary from '@/pages/SongsLibrary.vue';
import RegisterPage from '@/pages/RegisterPage.vue';
import LoginPage from '@/pages/LoginPage.vue';
import Cookies from 'js-cookie';
import {CookieManager} from "@/api/base/CookieManager";
import SongView from "@/components/SongView.vue";
import ChordPage from "@/pages/ChordPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/songsLibrary',
    name: 'songsLibrary',
    component: SongsLibrary,
  },
  {
    path: '/allGenres',
    name: 'allGenres',
    component: AllGenresPage,
  },
  {
    path: '/allMusicians',
    name: 'allMusicians',
    component: AllMusiciansPage,
  },
  {
    path: '/chordLibrary',
    name: 'chordLibrary',
    component: ChordLibraryPage,
  },
  {
    path: '/tuner',
    name: 'tuner',
    component: TunerPage,
  },
  {
    path: '/exampleSong',
    name: 'exampleSong',
    component: SongPage,
  },
  {
    path: '/addSong',
    name: 'addSong',
    component: AddSongPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterPage,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/chords',
    name: 'chords',
    component: ChordPage,
  },
  {
    path: '/songs/:songName/:authorPseudonym',
    name: 'songView',
    component: SongPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated: boolean = !!CookieManager.getCookie('token');

  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;
