import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { CookieManager } from './CookieManager';


const API_URL = process.env.VUE_APP_BACK_URL || '/api';


interface ApiClientOptions {
    baseURL?: string;
    timeout?: number;
    headers?: Record<string, string>;
}

export class BaseApi {
    private axiosInstance: AxiosInstance;
    private readonly baseURL: string;
    private isRefreshing: boolean = false;
    private failedQueue: any[] = [];

    constructor(options: ApiClientOptions = {}) {
        const { baseURL = API_URL, timeout = 10000, headers = {} } = options;

        this.baseURL = baseURL;

        this.axiosInstance = axios.create({
            baseURL,
            timeout,
            headers,
        });

        this.axiosInstance.interceptors.request.use(
            this.handleRequest,
            this.handleRequestError
        );

        this.axiosInstance.interceptors.response.use(
            this.handleResponse,
            this.handleResponseError
        );
    }

    private handleRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
        config.headers = config.headers ?? {};

        const token = CookieManager.getCookie('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    }

    private handleRequestError = (error: any): Promise<any> => {
        console.error('Request error:', error);
        return Promise.reject(error);
    }

    private handleResponse = (response: AxiosResponse): AxiosResponse => {
        return response;
    }

    private handleResponseError = async (error: any): Promise<any> => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            if (this.isRefreshing) {
                return new Promise((resolve, reject) => {
                    this.failedQueue.push({ resolve, reject });
                }).then(token => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return this.axiosInstance(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            this.isRefreshing = true;

            return new Promise((resolve, reject) => {
                this.refreshToken().then(token => {
                    CookieManager.setCookie('token', token);
                    this.axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    this.processQueue(null, token);
                    resolve(this.axiosInstance(originalRequest));
                }).catch((err) => {
                    this.processQueue(err, null);
                    reject(err);
                    window.location.href = '/login';
                }).finally(() => {
                    this.isRefreshing = false;
                });
            });
        }

        console.error('Response error:', error);
        return Promise.reject(error);
    }

    private processQueue(error: any, token: string | null = null) {
        this.failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        this.failedQueue = [];
    }

    private async refreshToken(): Promise<string> {
        const refreshToken = CookieManager.getCookie('refreshToken');
        if (!refreshToken) {
            throw new Error('No refresh token found');
        }

        const response = await this.axiosInstance.post<{ token: string }>('/user/refresh-token', { refreshToken });
        return response.data.token;
    }

    public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.axiosInstance.get<T>(url, config);
        return response.data;
    }

    public async post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.axiosInstance.post<T>(url, data, config);
        return response.data;
    }

    public async put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.axiosInstance.put<T>(url, data, config);
        return response.data;
    }

    public async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.axiosInstance.delete<T>(url, config);
        return response.data;
    }
}
