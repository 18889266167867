<template>
  <div class="form-container">
    <h2>Вход</h2>
    <v-snackbar color="red" v-model="showError" timeout="3000" top right multi-line>
      {{ errorMessage }}
      <v-btn class="snackbar-button" @click="showError = false">Закрыть</v-btn>
    </v-snackbar>
    <v-snackbar color="green" v-model="showSuccess" timeout="3000" top right multi-line>
      {{ successMessage }}
      <v-btn class="snackbar-button" @click="showSuccess = false">Закрыть</v-btn>
    </v-snackbar>

    <form @submit.prevent="login">
      <div class="form-group">
        <label for="username">Имя пользователя</label>
        <input type="text" id="username" v-model="username" required />
      </div>
      <div class="form-group">
        <label for="password">Пароль</label>
        <input :type="showPassword ? 'text' : 'password'" id="password" v-model="password" required />
        <label>
          <input type="checkbox" v-model="showPassword" /> Показать пароль
        </label>
      </div>
      <button type="submit">Вход</button>
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import authApi from "@/api/AuthApi";


import {
  VBtn,
  VSnackbar,
} from 'vuetify/components';

const username = ref('');
const password = ref('');
const showPassword = ref(false);

const showError = ref(false);
const showSuccess = ref(false);
const errorMessage = ref('');
const successMessage = ref('');

const login = async () => {
  try {
    const requestBody = {
      username: username.value,
      password: password.value,
    };

    const response = await authApi.signInUser(requestBody);

    if (response && response.token) {
      localStorage.setItem('token', response.token);
      successMessage.value = 'Вход выполнен успешно';
      showSuccess.value = true;
    }
  } catch (error) {
    errorMessage.value = 'Ошибка входа. Проверьте данные и попробуйте снова.';
    showError.value = true;
    console.error('Ошибка входа:', error);
  }
};
</script>

<style scoped>
.form-container {
  max-width: 1150px;
  margin: 0 auto;
  height: calc(60vh - 70px);
  display: flex;
  flex-direction: column;
}

h2 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  background-color: #333;
  color: white;
  border: 1px solid #ccc;
}

input::placeholder {
  color: #ccc;
}

button {
  padding: 10px 20px;
  background-color: #ff7e07;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #e66a00;
}

.snackbar-button {
  color: #ffffff;
  background-color: #ff6600;
}
</style>
